import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    background: #E5E5E5;
    padding: 20px;
    height: 100%;
    @media screen and (min-width: 1075px) {
        flex-direction: row;
    }
`
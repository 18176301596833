export default (value) => {
    if (value.length < 12) {
        if (!value || value.length !== 11
            || value === "00000000000"
            || value === "11111111111"
            || value === "22222222222"
            || value === "33333333333"
            || value === "44444444444"
            || value === "55555555555"
            || value === "66666666666"
            || value === "77777777777"
            || value === "88888888888"
            || value === "99999999999") {
            return false
        }
        let sum = 0,
            result;
        for (let i = 1; i <= 9; i++) {
            sum += parseInt(value.substring(i - 1, i)) * (11 - i)
        }
        result = ((sum * 10) % 11)
        if((result === 10) || (result === 11)) {
            result = 0
        }
        if (result !== parseInt(value.substring(9, 10))) {
            return false
        }
        sum = 0
        for (let i = 1; i <= 10; i++) {
            sum += parseInt(value.substring(i - 1, i)) * (12 - i)
        }
        result = ((sum * 10) % 11)
        if((result === 10) || (result === 11)) {
            result = 0
        }
        return (result === parseInt(value.substring(10, 11)))
    }else {
        if ( !value || value.length !== 14
            || value === "00000000000000"
            || value === "11111111111111"
            || value === "22222222222222"
            || value === "33333333333333"
            || value === "44444444444444"
            || value === "55555555555555"
            || value === "66666666666666"
            || value === "77777777777777"
            || value === "88888888888888"
            || value === "99999999999999") {
            return false
        }

        const validTypes =
            typeof value === 'string' || Number.isInteger(value) || Array.isArray(value)

        if (!validTypes) return false

        const match = value.toString().match(/\d/g)
        const numbers = Array.isArray(match) ? match.map(Number) : []

        if (numbers.length !== 14) return false

        const items = [...new Set(numbers)]
        if (items.length === 1) return false

        const calc = (x) => {
            const slice = numbers.slice(0, x)
            let factor = x - 7
            let sum = 0

            for (let i = x; i >= 1; i--) {
                const n = slice[x - i]
                sum += n * factor--
                if (factor < 2) factor = 9
            }

            const result = 11 - (sum % 11)

            return result > 9 ? 0 : result
        }

        const digits = numbers.slice(12)

        const digit0 = calc(12)
        if (digit0 !== digits[0]) return false

        const digit1 = calc(13)
        return digit1 === digits[1]
    }
}
import styled from "styled-components";
import ButtonStyle from "@material-ui/core/Button";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 24px;
    border-radius: 8px;
    margin-right: 20px;
    background-color: #FFFFFF;
    h3 {
        margin-bottom: 13px;
    }
`


export const Button = styled(ButtonStyle)`
    && {
        cursor: pointer;
        color: #FFFFFF;
        padding: 8px 16px;
        width: calc(50% - 4px);
        &+&& {
            margin-left: 8px;
        }
        min-width: fit-content;
        &.whatsapp {
            background-color: #6FCF97;
            width: 100%;
            margin-top: 8px;
            margin-left: 0!important;
            &:hover {
                background-color: #74B58F;
            }
            
            @media screen and (min-width: 700px) {
                order: 1;
                margin-right: 16px;
                margin-top: 0;
            }
        }

        &.downloadBillet {
            background-color: #4373ca;
            width: 100%;
            padding: 4px 16px;
            margin-top: 8px;
            margin-left: 30px !important;
            box-shadow: 1px 2px 4px #262626;
            &:hover {
                background-color: #4A90E2;
            }
        }

        &.phone {
            background-color: #F29530;
            &:hover {
                background-color: #EA8416;
            }
            
            
            @media screen and (min-width: 700px) {
                order: 2;
                margin-right: 16px;
            }
        }
        &.callMe {
            background-color: transparent;
            color: #4A90E2;
            border: 1px solid #4A90E2;
            &:hover {
                background-color: #4A90E2;
                color: #FFFFFF;
            }
            
            @media screen and (min-width: 700px) {
                order: 3;
                
                margin-left: 0!important;
            }
        }
        span {
            text-transform: none;
            font-size: 14px;
        }
        svg {
            height: 15px;
            margin-right: 8px;
        }
        @media screen and (max-width: 400px) {
            width: 100%;
            &+&& {
                margin-top: 8px;
                margin-left: 0!important;
            }
        }
        
        
        @media screen and (min-width: 700px) {
            width: fit-content!important;
        }
    }
`
import React, {useState} from 'react'
import InputMask from 'react-input-mask';
import {Container, InputLabel, Button, FormControl, OutlinedInput, FormHelperText, Form} from './styled'
import Logo from 'assets/images/logo.png'
import moment from 'moment';
import useStateCallback from 'utils/useStateCallback'
import validCPF from "utils/validCPF";
import { toast } from 'react-toastify';
/*
import {
    DatePicker,
} from '@material-ui/pickers';
*/

const Access = (props) => {
    const [form, setForm] = useStateCallback({
        cpf: {value: "", error: ""},
        nro_acordo: {value: "", error: ""},
    })



    const handleChange = (e) => {
        const {value, name, type} = e.target
        setForm(prevState => ({
            ...prevState,
            [name]: type === "checkbox" ? {
                ...prevState[name],
                value: !prevState[name].value
            } : {...prevState[name], value}
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setForm(prevState => {
            return Object.keys(prevState).reduce((prev, curr) => {
                let formValue = {...prevState[curr], error: ''}
                if (prevState[curr].value.length === 0) {
                    formValue = {...formValue, error: "Campo obrigatório"}
                } else {
                    if (curr === 'cpf' && !validCPF(prevState[curr].value.replace(/[^0-9]/g, ''))) {
                        formValue = {...formValue, error: `${prevState[curr].value.match(/\d/g).join('').length < 12 ? "CPF" :  "CNPJ"} Inválido.`}
                    }
                }
                prev[curr] = formValue

                return prev
            }, {})
        }, async (currForm) => {
            let error = false
            let data = Object.keys(currForm).reduce((prev, curr) => {
                if (curr === 'cpf') {
                    prev[curr] = currForm[curr].value.replace(/[^0-9]/g, '')
                }  else {
                    prev[curr] = currForm[curr].value
                }
                if (!error) {
                    error = currForm[curr].error.length > 0
                }
                return prev
            }, {})

            if (!error) {
                const response = await props.handleSubmit(data)
                if (response.error ===  "CPF informado não existe") {
                    setForm(prevState => ({
                        ...prevState,
                        cpf: {...prevState.cpf, error: `${data.cpf.match(/\d/g).join('').length < 12 ? "CPF" :  "CNPJ"} sem cadastro.`},
                    }))
                } else if (response.error ===  "Informações incorretas") {
                    setForm(prevState => ({
                        ...prevState,
                        cpf: {...prevState.cpf, error:  `${data.cpf.match(/\d/g).join('').length < 12 ? "CPF" : "CNPJ"} e número do acordo não condizem.`},
                    }))
                }
            }
        })
    }

    return <Container>
        <img src={Logo} alt="Logo natura"/>
        <Form onSubmit={handleSubmit}>
            <FormControl>
                <InputLabel error={form.cpf.error.trim() !== ''} htmlFor="cpf" shrink={true}>CPF ou CNPJ</InputLabel>
                <InputMask
                    mask={((form.cpf.value === '' || form.cpf.value === '   .   .   -   ') || (form.cpf.value.match(/\d/g) && form.cpf.value.match(/\d/g).join('').length < 12)) ? "999.999.999-999" : "99.999.999/9999-99"}
                    value={form.cpf.value}
                    helperText={form.cpf.error.trim() !== ''}
                    maskChar={form.cpf.value.match(/\d/g) && form.cpf.value.match(/\d/g).join('').length === 11 ? "" : " "}
                    onChange={handleChange}
                    name='cpf'>
                    {() => <OutlinedInput error={form.cpf.error.trim() !== ''} name="cpf" id="cpf"
                                          placeholder="000.000.000-00"/>}
                </InputMask>

                <FormHelperText error={form.cpf.error.trim() !== ''}>{form.cpf.error}</FormHelperText>

            </FormControl>
            <FormControl>
                <InputLabel error={form.nro_acordo.error.trim() !== ''} htmlFor="nro_acordo" shrink={true}>
                    Número do acordo
                </InputLabel>
                <OutlinedInput error={form.nro_acordo.error.trim() !== ''} value={form.nro_acordo.value}
                               onChange={handleChange} name='nro_acordo' id="nro_acordo" placeholder="0000000000000"/>
                <FormHelperText error={form.nro_acordo.error.trim() !== ''}>{form.nro_acordo.error}</FormHelperText>
            </FormControl>
            <Button variant="contained" type="submit">
                {props.loading ? "Carregando..." : "Entrar"}
            </Button>
        </Form>
    </Container>
}

export default Access
import styled from "styled-components";
import BG from 'assets/images/bg.png'
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

export const FormContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(${BG});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    
  @media screen and (min-width: 800px) {
   
    >div {
      position: absolute;
      left: calc(20% - 100px);
    }
  }
  @media screen and (min-width: 1272px) {
    
    >div {
      position: absolute;
      left: 150px;
    }
  }

`

export const ContainerButton = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  padding-bottom: 16px;
`
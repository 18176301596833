import React, {useState} from "react";
import {Container, Content} from './styled'
import Header from './header'
import Resume from './resume'
import Billet from './billet'
import Channel from './channel'

const Main = (props) => {
    const [billetSelected, setBilletSelected] = useState([])
    const {data, form} = props.seller
    const boletos = data
    const handleBilletSelected = (order) => {
        setBilletSelected(prevState => prevState.includes(order) ? prevState.filter(x => x !== order) : [...prevState, order])
    }

    return <Container>
        <Header handleLogout={props.handleLogout}/>
        <Content>
            <div>
                <Resume name={form.name} cpf={form.cpf} agreement={form.nro_acordo}/>
                <Channel seller={props.seller} order={billetSelected}/>
            </div>
            <Billet boletos={boletos} billetSelected={billetSelected} handleBilletSelected={handleBilletSelected} cpf={form.cpf} action={props.action}/>
        </Content>

    </Container>
}

export default Main
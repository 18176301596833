import styled from 'styled-components';

export const Container = styled.div`
    color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    min-height: 199px;
    margin-bottom: 20px;
    @media screen and (min-width: 1075px) {
        min-width: 360px;
        margin-right: 20px;
        margin-bottom: 0;
    }
  
`
export const Title  = styled.div`
    display: flex;
    padding: 19px 16px;
    border-radius: 8px 8px 0 0;
    font-size: 24px;
    background: #1F308A;
    justify-content: space-between;
`
export const Level = styled.div`
    background-color: #ffffff;
    padding: 5px;
    font-size: 16px;
    border-radius: 3px;
    &.gold{
        color: #C99D00;
    }
    &.silver{
        color: #969696;
    }
    &.bronze{
        color: #A05905;
    }
    &.seed{
        color: #2D7D08;
    }
`
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    background: #0D1C69;
    padding: 16px;
    
    border-radius: 0 0 8px 8px;
    >p {
        &:first-child {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 6px;
        }
    }
    > span {
        display: block;
        font-size: 12px;
        margin-top: 12px;
    }
    @media screen and (min-width: 1075px) {
        >span {
            max-width: 328px;
        }
    }
  
`
export const Info = styled.div`
    background-color: #253378;
    padding: 6px 10px;
    &+&& {
        margin-left: 16px;
    }
    > p {
        &:first-child {
            font-size: 12px;
        }
        &:last-child {
            font-weight: bold;
            font-size: 18px;
        }
    }
`
export const Summary = styled.div`
    display: flex;
    margin-top: 12px;
`
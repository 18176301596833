import styled, {createGlobalStyle} from 'styled-components'
import {normalize} from 'styled-normalize'
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer as ToastContainerStyle} from 'react-toastify';

export const GlobalStyles = createGlobalStyle`
    ${normalize};
    html,body,#root {
        width: 100%;
        height: 100%;
        background: #E5E5E5;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif;
    }
`

export const ToastContainer = styled(ToastContainerStyle).attrs({
    // custom props
})`
  .Toastify__toast-container {}
  .Toastify__toast {}
  .Toastify__toast--error {}
  .Toastify__toast--warning {}
  .Toastify__toast--success {}
  .Toastify__toast-body {}
  .Toastify__progress-bar {
      background: linear-gradient(to right, #bd838a, #7a051d)!important;
  }
`;

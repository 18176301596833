import styled from "styled-components";
import ButtonStyle from "@material-ui/core/Button";
import IconButtonStyle from "@material-ui/core/IconButton";

import DialogStyle from '@material-ui/core/Dialog';
export const DialogContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    
`


export const ButtonContainer = styled.div`
    display: flex;
    grid-gap: 16px;
    margin-top: 24px;
    @media screen and (max-width: 320px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        > button {
            width: 100%!important;
        }
    }
`
export const Button = styled(ButtonStyle)`
    && {
        cursor: pointer;
        span {
            text-transform: none;
        }
        &.confirm {
            background-color: #F29530;
            color: #FFFFFF;
            width: calc(100% - 96px);
            &:hover {
                background-color: #EA8416;
            }
            
            
            @media screen and (max-width: 500px) {
                width: calc(50% - 8px);
            }
        }
        &.back{
            color: #ED8434;
            background-color: transparent;
            border: 1px solid #ED8434;
            width: fit-content;
            padding: 0 24px;
            svg {
                height: 14px;
                width: 17px;
                margin-right: 6px;
            }
            &:hover, &:focus {
                background-color: #ED8434;
                color: #FFFFFF;
            } 
            
            @media screen and (max-width: 500px) {
                width: calc(50% - 8px);
            }
        }
        &.horary{
            color: #4A90E2;
            background-color: transparent;
            border: 1px solid #4A90E2;
            width: fit-content;
            padding: 8px 14px;
            span {
                font-size: 14px;
            }
            &:hover, &.selected {
                background-color: #4A90E2;
                color: #FFFFFF;
            } 
        }
    }
`

export const IconButton = styled(IconButtonStyle)`
    && {
        cursor: pointer;
        border-radius: 0;
        padding: 6px;
        svg {
            height: 14px;
        }
    }
`

export const Dialog = styled(DialogStyle)``
export const DialogTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h5 {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
    }
`
export const ErrorMessage = styled.span`
    font-size: 14px;
    color: #CC3537;
`
export const DialogContent = styled.div`
    > p {
        margin-bottom: 16px;
        color: #898888;
    }
    >div {
        .MuiPickersBasePicker-container {
            align-items: center;
        }
        &:not(${ButtonContainer}) {
            @media screen and (min-width: 600px) {
                display: flex;
            }
        }
    }
`

export const HoraryContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    > p {
        color: #333333;
        margin-bottom: 16px;
    }
    > span:not(${ErrorMessage}) {
        display: flex;
        color: #B3ADBD;
        font-size: 14px;
        align-items: center;
        column-gap: 8px;
        justify-content: center;
        margin-top: 8px;
        svg {
            height: 14px;
        }
        @media screen and (min-width: 600px) {
            display: none;
        }
    }
    @media screen and (min-width: 600px) {
        display: flex;
        padding: 22px 0;
    }
`
export const HoraryList = styled.ul`
    list-style: none;
    display: flex;
    max-width: 100%;
    overflow-x: scroll;
    grid-gap: 14px;
    @media screen and (min-width: 600px) {
        flex-wrap: wrap;
    }
`
export const HoraryItem = styled.li`
    
`
import React, {useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogContainer,
    IconButton,
    ButtonContainer,
    HoraryContainer,
    HoraryItem,
    ErrorMessage,
    HoraryList
} from './styled'
import {ReactComponent as CloseIcon} from 'assets/icons/close.svg'
import {ReactComponent as BackIcon} from 'assets/icons/back.svg'
import {ReactComponent as ArrowLeftIcon} from 'assets/icons/arrowLeft.svg'
import {ReactComponent as ArrowRightIcon} from 'assets/icons/arrowRight.svg'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import {DatePicker} from "@material-ui/pickers";
import moment from "moment";
import useStateCallback from "../../../../../utils/useStateCallback";
import classNames from "classnames";
import {toast} from "react-toastify";

const CallMeDialog = (props) => {
    const [form, setForm] = useStateCallback({
        date: {value: moment().add(1, 'day'), error: ""},
        horary: {value: "", error: ""}
    });

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
    const format = (value) => {
        return value < 10 ? `0${value}` : value
    }

    const handleSubmit = () => {
        setForm(prevState => {
            return Object.keys(prevState).reduce((prev, curr) => {
                let formValue = {...prevState[curr], error: ''}
                if (prevState[curr].value.length === 0) {
                    formValue = {...formValue, error: "Campo obrigatório"}
                }
                prev[curr] = formValue
                return prev
            }, {})
        }, async (currForm) => {
            let error = false
            let data = Object.keys(currForm).reduce((prev, curr) => {
                if (curr === 'date') {
                    prev[curr] = moment(currForm[curr].value).format('DD/MM/YYYY')
                } else {
                    prev[curr] = currForm[curr].value
                }
                if (!error) {
                    error = currForm[curr].error.length > 0
                }
                return prev
            }, {})

            if (!error) {
                const response = await fetch(`${process.env.REACT_APP_API_PATH}/api/v2/incoming/landing_page`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        department: 2,
                        cpf: props.seller.form.cpf,
                        birthdate: props.seller.form.birthdate,
                        data: {
                            scheduling: `${data.date} ${data.horary}`
                        }
                    })
                })
                const content = await response.json()
                if (response.status === 201 && !content.error) {
                    props.handleClose()
                    toast(<div>
                        <h4>Ligação agendada com sucesso!</h4>
                        <p>Dia {data.date} ás {data.horary}</p>
                    </div>)
                }
                return content

            }
        })
    }


    const handleChange = (e) => {
        const {value, name, type} = e.target
        setForm(prevState => ({
            ...prevState,
            [name]: type === "checkbox" ? {
                ...prevState[name],
                value: !prevState[name].value
            } : {...prevState[name], value}
        }))
    }

    return <Dialog fullScreen={fullScreen} open={props.open}>
        <DialogContainer>
            <DialogTitle><h5>Agendar horário</h5><IconButton
                onClick={() => props.handleClose()}><CloseIcon/></IconButton></DialogTitle>
            <DialogContent>
                <p>Qual é o melhor dia e horário para entrarmos em contato com você?</p>
                <div>
                    <div>
                        <DatePicker
                            autoOk
                            disableToolbar={true}
                            variant="static"
                            openTo="date"
                            disablePast={true}
                            value={form.date.value}
                            onChange={(date) => handleChange({target: {name: 'date', value: date}})}
                        />

                        {form.date.error && <ErrorMessage>{form.date.error}</ErrorMessage>}
                    </div>
                    <HoraryContainer>
                        <p>Horários disponíveis</p>
                        <HoraryList>
                            {[...Array(10).keys()].map(x => <HoraryItem><Button
                                onClick={() => handleChange({target: {name: 'horary', value: `${format(8 + x)}:00`}})}
                                className={classNames({
                                    selected: form.horary.value === `${format(8 + x)}:00`
                                }, "horary")}>
                                {format(8 + x)}:00
                            </Button></HoraryItem>)}
                        </HoraryList>
                        {form.horary.error && <ErrorMessage>{form.horary.error}</ErrorMessage>}
                        <span><ArrowLeftIcon/>Arraste para ver mais<ArrowRightIcon/></span>
                    </HoraryContainer>
                </div>
                <ButtonContainer>
                    <Button className="back" onClick={props.handleClose}><BackIcon/>Voltar</Button>
                    <Button className="confirm" onClick={handleSubmit}>Agendar ligação</Button>
                </ButtonContainer>
            </DialogContent>
        </DialogContainer>
    </Dialog>
}

export default CallMeDialog
import React from "react";
import {
  Container,
  Title,
  Description,
  List,
  ListItem,
  Scrollbars,
  Checkbox,
  Status,
} from "./styled";
import classNames from "classnames";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import moment from "moment";
import { toast } from "react-toastify";
import { Button } from "../channel/styled";
const Billet = (props) => {
  const handleCopy = async (linha_digitavel, nro_acordo) => {
    await navigator.clipboard.writeText(linha_digitavel);
    props.action({
      nro_acordo: nro_acordo,
      cpf: props.cpf,
      acao: "copy",
    });
    toast("Copiado com sucesso!");
  };

  const handleDownloadBillet = async (fileUrl) => {
    if (props.billetSelected.length === 0) {
      toast("Selecione um boleto para baixar");
      return;
    }

    const links = fileUrl.map((fileUrl, index) => {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `boleto-${props.billetSelected[index]}.pdf`;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return link;
    });
  };

  const handleExistDuplicateBillet = () => {
    
  }

  return (
    <Container>
      <Title>Boletos</Title>
      <Description>Para selecionar mais de um boleto, pressione.</Description>
      <Scrollbars>
        <List>
          {props.boletos
          .reduce((uniqueBoletos, currentBoleto) => {
            const existsBoleto = uniqueBoletos.find(
              (boleto) => boleto.nro_acordo === currentBoleto.nro_acordo
            )

            if (!existsBoleto) {
              uniqueBoletos.push(currentBoleto)
            }

            return uniqueBoletos
          }, [])
          .map((x) => (
            <ListItem
              className={classNames({
                selected: props.billetSelected.includes(x.nro_acordo),
              })}
              onClick={() => props.handleBilletSelected(x.nro_acordo)}
            >
              <Checkbox checked={props.billetSelected.includes(x.nro_acordo)} />
              <div>
                <h5
                  className={classNames({
                    complete: x.status === "pago",
                  })}
                >
                  {x.status === "pago" ? (
                    <>&nbsp;R$ {x.valor_doc}&nbsp;</>
                  ) : (
                    <>R$ {x.valor_doc}</>
                  )}
                </h5>
              </div>
              <div className="status">
                {x.linha_digitavel?.length > 0 && (
                  <p
                    className="linha_digitavel"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopy(x.linha_digitavel, x.nro_acordo);
                    }}
                  >
                    <span>Código de barras:</span> {x.linha_digitavel}{" "}
                    <CopyIcon />
                  </p>
                )}
                <p>
                  <span>Vencimento:</span>{" "}
                  {moment(x.data_vencto, "DD/MM/YYYY").format("DD/MM/YYYY")}
                </p>
              </div>
            </ListItem>
          ))}
        </List>
        <Button
          className="downloadBillet"
          onClick={() =>
            handleDownloadBillet(
              props.boletos
                .filter((x) => props.billetSelected.includes(x.nro_acordo))
                .map((x) => x.link_boleto?.url)
            )
          }
        >
          Baixe o boleto aqui
        </Button>
      </Scrollbars>
    </Container>
  );
};

export default Billet;

import React from "react";
import {Container,Button} from './styled'
import Logo from "assets/images/logo.png"
import {ReactComponent as BackIcon} from 'assets/icons/back.svg'
const Header = (props) => {
    return <Container>
        <img src={Logo} alt="Logo almeida mendonça"/>

        <Button variant="contained" onClick={props.handleLogout}><BackIcon/>Sair</Button>
    </Container>
}

export default Header
import styled from "styled-components";

import FormControlStyle from '@material-ui/core/FormControl';
import InputLabelStyle from '@material-ui/core/InputLabel';
import FormHelperTextStyle from '@material-ui/core/FormHelperText';
import OutlinedInputStyle from '@material-ui/core/OutlinedInput';
import ButtonStyle from '@material-ui/core/Button';

export const Container = styled.div`
    width: 90%;
    max-width: 300px;
    max-height: 344px;
    display: flex;
    background: #FFFFFF;
    box-shadow: 10px 20px 50px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    >img {
        width: 55%;
        margin-bottom: 44px;
    }
`

export const FormControl = styled(FormControlStyle)`
    && { 
        height: 40px;
        margin-top: 18px;
        margin-bottom: 6px;
        &+&& {
            margin-top: calc(18px + 16px);
        }
    }
`
export const InputLabel = styled(InputLabelStyle)`
    && {
        &[data-shrink="true"] {
            top: -18px;
        }
    }
`
export const OutlinedInput = styled(OutlinedInputStyle)`
    && {
        min-height: 40px;
        input {
            font-size: 14px;
            padding: 12px 14px;
        }
    }
`
export const Form = styled.form`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    border-radius: 0 0 8px 8px;
    &.form_container {
        height: 100%;
        >div {
           display: flex;
           flex-direction: column;
        }
    }
    .label {
        font-weight: bold;
        color: #074F70;
    }
    input {
        &[type="number"] {
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none; 
                margin: 0; 
            }
        }
        &[type="date"] {
            &::-webkit-calendar-picker-indicator {
                display: none;
                -webkit-appearance: none;
            }
        }
    }    
    >button {
        margin-top: 30px;
        padding: 8px 0;
    }
`

export const Button = styled(ButtonStyle)`
  && {
    cursor: pointer;
    background-color: #BD242C;;
    color: #FFFFFF;

    span {
      font-weight: bold;
    }

    &:hover, &:focus {
      background-color: #861b20;
    }
  }
`

export const FormHelperText = styled(FormHelperTextStyle)`
    && {
        text-align: right;
        font-size: .65rem;
    }
`
import React from "react";
import {Container, FormContainer, ContainerButton} from "./styled";
import BG from 'assets/images/bg.png'
import Form from 'components/pages/login/form'
import Footer from 'components/pages/login/footer'
import {ReactComponent as WhatsAppIcon} from "assets/icons/whatsapp.svg";
import { Button } from "../main/channel/styled";
const Login = (props) => {
    const handleLink  = (link) => {
        let a = document.createElement('a')
        a.href = link
        a.target = '_blank'
        a.style = 'display:none;'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    return <Container>
        <FormContainer>
            <Form {...props}/>
            <ContainerButton>
                <Button className="whatsapp" onClick={() => handleLink(`https://api.whatsapp.com/send?phone=5511965882429`)}><WhatsAppIcon/>Whatsapp</Button>
            </ContainerButton>
            </FormContainer>
        <Footer/>
    </Container>
}

export default Login
import React, {useState} from "react";
import Login from "components/pages/login";
import Main from "components/pages/main";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {GlobalStyles, ToastContainer} from './styled'
import MomentUtils from '@date-io/moment';
import "moment/locale/pt-br"
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#333333",
        },
        secondary: {
            main: "#1F308A"
        }
    },
})

function App() {
    const [seller, setSeller] = useState(false)
    const [loading, setLoading] = useState(false)
    const sendAction = async (data)=>{
        await fetch(`${process.env.REACT_APP_API_PATH}/api/v1/crm/landing_page/log`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({department: 28, ...data})
        })
    }

    
    const handleSubmitCustomer = async (data) => {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_API_PATH}/api/v1/crm/landing_page`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({department: 28, ...data})
        })
        const content = await response.json()
        if (response.status === 200 && !content.error) {
            if (!Array.isArray(content) || (content.length === 0 || content.filter(mailing => Array.isArray(mailing.lote_data_list) && mailing.lote_data_list.filter((lote) => lote.data.filter(y => Array.isArray(y.acordo) && y.acordo.length > 0).length > 0).length > 0).length === 0)) {
                return {error: 'Informações incorretas'}
            }
            sendAction({acao:"read",...data})
            setSeller({
                data: content.reduce((prev, curr) => {
                    if (Array.isArray(curr.lote_data_list)) {
                        curr.lote_data_list.map((lote) => {
                            lote.data.map(y => Array.isArray(y.acordo) && (prev.push(...y.acordo)))
                        })
                    }
                    return prev
                }, []), form: {...data, name: content[0].lote_data_list[0].data[0].name}
            })
        }
        setLoading(false)
        return content
    }


    const handleLogout = () => {
        setSeller(false)
    }
    return <ThemeProvider theme={theme}>
        <GlobalStyles/>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
            {!seller ? <Login handleSubmit={handleSubmitCustomer} loading={loading} /> :
                <Main handleLogout={handleLogout} seller={seller} action={sendAction} />}
            <ToastContainer position="bottom-right"
                            autoClose={2500}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover/>
        </MuiPickersUtilsProvider>
    </ThemeProvider>;
}

export default App;

import React from "react";
import {Container,Title,Level,Content,Summary,Info} from './styled'
import classNames from "classnames";
import PropTypes from 'prop-types';

const Header = ({name, cpf, agreement}) => {
    const hideCpfCnpj = (word, replacement) => {
        const onlyNumber = word.replace(/\D/g, "")
        const size = onlyNumber.length < 12 ? 5 : 7
        const hideString = [...Array(size+1).keys()].map(() => replacement).join('') + onlyNumber.substr(size + replacement.length)
        if (onlyNumber.length < 12) {
            return hideString.replace(/([\d|*]{3})([\d|*]{3})([\d|*]{3})([\d|*]{2})/, "$1.$2.$3-$4")
        }
        return hideString.replace(/([\d|*]{2})([\d|*]{3})([\d|*]{3})([\d|*]{4})([\d|*]{2})/, "$1.$2.$3/$4-$5")
    }
    return <Container>
        <Title>
            Bem vindo!
        </Title>
        <Content>
            <p>{name}</p>
            <p>{hideCpfCnpj(cpf, '*')}</p>
            <Summary>
                <Info>
                    <p>Número do acordo</p>
                    <p>{agreement}</p>
                </Info>
            </Summary>
        </Content>
    </Container>
}

Header.defaultProps = {
    name: 'Nome',
    cpf: '***.***.***-**',
    value: '-',
};


Header.propTypes = {
    name: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default Header
import styled from 'styled-components';
import ListItemStyle from '@material-ui/core/ListItem'
import ScrollbarsStyle from 'react-custom-scrollbars'
import CheckboxStyle from '@material-ui/core/Checkbox';

export const Container = styled.div`
    height: 100%;
    flex: 1 0;
`
export const Title = styled.h3`
    font-size: 20px;
    color: #333333;
    margin-bottom: 8px;
`
export const Description = styled.p`
    font-size: 14px;
    color: #898888;
    margin-bottom: 16px;
    /*@media screen and (min-width: 750px) {
        display: none; 
    }*/
`

export const List = styled.ul`
    display: flex;
    flex-direction: column;
`
export const Scrollbars = styled(ScrollbarsStyle)`
    && {
        
        height: calc(100% - 61px)!important; 
        @media screen and (min-width: 750px) {
            height: calc(100% - 32px)!important; 
        }
    }
`

export const ListItem = styled(ListItemStyle).attrs({
    button: true,
})`
    && {
        padding: 16px;
        box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.15);
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        border-radius: 4px;
        justify-content: space-between;
        border: 1px solid #FFFFFF;
        flex-wrap: wrap;
        &+&& {
            margin-top: 16px;
        }
        &.selected {
            border: 1px solid #1F308A;
        }
        &:hover,&:focus {
            background-color: #FFFFFF;
        }
        >span.description {
            width: 100%;
            display: block;
            font-size: 12px;
            margin-top: 12px;
        }
        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            &.status {
                align-items: flex-end;
                .linha_digitavel {
                    padding: 10px;
                    svg {
                        height: 12px;
                    }
                }
            }
        }
        h5 {
            font-weight: bold;
            font-size: 20px;
            color: #000000;
            &.complete {
                text-decoration: line-through;
                color: #898888;
            }
        }
        p {
            color: #898888;
            font-size: 12px;
            white-space: nowrap;
            span {
                color: #333333;
            }
        }
        
        @media screen and (min-width: 650px) {
            >div {
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 22px;
                &.status {
                    align-items: center;
                }
                &:not(.status) {
                    flex: 1;
                    justify-content: space-between;
                    padding: 0 22px;
                }
            }
        }
    }
`

export const Status = styled.div`
    padding: 3.5px 8px;
    width: fit-content;
    font-size: 14px;
    &.open {
        color: #4F8C13;
        background-color: #e4f1da;
    } 
    &.late {
        color: #C42121;
        background-color: #fbe0e0;
    } 
    &.complete {
        color: #333333;
        background-color: #e5e5e5;
    } 
`
export const Checkbox = styled(CheckboxStyle).attrs({
    size: 'small'
})`
    && {
        padding: 14px;
        border-right: 1px solid #DEDEDE;
        border-radius: 0;
        @media screen and (max-width: 750px) {
            display: none; 
        }
    }
`
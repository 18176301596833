import styled from "styled-components";
import ButtonStyle from "@material-ui/core/Button";
import IconButtonStyle from "@material-ui/core/IconButton";
import DialogStyle from '@material-ui/core/Dialog';
import QrBorder from 'assets/images/qrcode.png'
export const Container = styled.footer`
    background: #FFFFFF;
    
    >div {
        padding: 15px 22px;
        display: flex;
        align-items: center;
    }
    @media screen and (min-width: 550px) {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 15px 22px;
        >div {
            padding: 0;
        }
    }
`

export const Divider = styled.div`
    width: 1px;
    height: 32px;
    background-color: #dedede;
    margin: 0 30px 0 20px;
    @media screen and (max-width: 600px) {
        display: none;
    }
`
export const Download = styled.div`
    justify-content: flex-end;
    @media screen and (max-width: 550px) {
        border-bottom: 1px solid #DEDEDE;
    }
    > p {
        max-width: 65px;
        margin-right: 12px;
        color: #333333;
        font-size: 12px;
    }
`

export const SocialList = styled.ul`
    list-style: none;
    display: flex;
`

export const SocialItem = styled.li`
    
`

export const PoweredBy = styled.p`
    color: #898888;
    font-size: 12px;
    display: flex;
    align-items: center;
    a {
        text-decoration: none;
        margin-left: 6px;
    }
`

export const Group = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const SocialButton = styled(IconButtonStyle)`
    && {
        border-radius: 0;
        width: 30px;
        height: 30px;
        padding: 0;
        &:hover, &:focus {
            svg {
                color: #F3AA42;
            }
        } 
        svg {
            height: 14px;
            max-width: 17px;
            color: #BEBEBE;
        }
    }
`


export const Button = styled(ButtonStyle)`
    && {
        background-color: #E5E5E5;
        color: #383838;
        padding: 0;
        width: 90px;
        height: 32px;
        span {
            text-transform: none;
            
            svg {
                width: 100%;
                height: 100%;
            }
        }
        &.back{
            color: #ED8434;
            background-color: transparent;
            border: 1px solid #ED8434;
            padding: 13px 16px;
            svg {
                height: 14px;
                width: 17px;
                margin-right: 6px;
            }
            &:hover, &:focus {
                background-color: #ED8434;
                color: #FFFFFF;
            } 
        }
        &+&& {
            margin-left: 16px;
        }
    }
`

export const Dialog = styled(DialogStyle)`
    && {
        
    }
`
export const DialogContent = styled.div`
    padding: 48px 40px 40px;
    display: flex;
    > div {
        &:first-child {
            padding: 24px 22px 24px 0;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            h4 {
                font-size: 28px;
                color: #333333;
                
            } 
            p {
                font-size: 18px;
                color: #898888;
                margin-top: 16px;
            }
            ${Button} {
                align-self: flex-end;
            }
        }
    }
`

export const QRCodeContainer = styled.div`
    padding: 24px;
    box-shadow: 0px 30px 40px -10px rgba(53, 32, 87, 0.1);
    border-radius: 4px;
    > div {
        padding: 16px;
        background: url(${QrBorder});
        background-position: center;
        background-size: 100%;
    }
`

import styled from 'styled-components';
import ButtonStyle from "@material-ui/core/Button";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 20px 24px;
    background-color: #ffffff;
    img {
        height: 25px;
    }
`


export const Button = styled(ButtonStyle)`
    && {
        cursor: pointer;
        background-color: #BD242C;;
        color: #FFFFFF;
        span {
            font-weight: bold;
            text-transform: none;
        }
        svg {
            height: 11px;
            margin-right: 8px;
        }
        &:hover, &:focus {
            background-color: #861b20;
        }
    }
`

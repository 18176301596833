import React, {useState} from "react";
import {Container, Button} from "./styled";
import {ReactComponent as PhoneIcon} from "assets/icons/phone.svg";
import {ReactComponent as WhatsAppIcon} from "assets/icons/whatsapp.svg";
import DialogCallMe from './dialog'
const Channel = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false)

    const handleLink  = (link) => {
        let a = document.createElement('a')
        a.href = link
        a.target = '_blank'
        a.style = 'display:none;'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    return <Container>
        <h3>Formas de contato</h3>
        {/*<Button className="phone" onClick={() => handleLink(`tel:08007628872`)}><PhoneIcon/>0800 762 8872</Button>
        <Button className="callMe" onClick={() => setDialogOpen(true)}><PhoneIcon/>Agendar ligação</Button>*/}
        {/*<Button className="whatsapp" onClick={() => handleLink(`https://api.whatsapp.com/send?phone=5511974887066&text=Ol%C3%A1%20meu%20CPF%20é%20${props.seller.form.cpf}%20e%20gostaria%20de%20falar%20sobre%20o${props.order.length > 1 ? 's' : ''}%20pedido${props.order.length > 1 ? 's' : ''}%20${props.order.join(',%20').replace(/,%20([^,]*)$/, '%20e%20$1')}`)}><WhatsAppIcon/>Whatsapp</Button>*/}
        <Button className="whatsapp" onClick={() => handleLink(`https://api.whatsapp.com/send?phone=5511965882429&text=Ol%C3%A1%20meu%20${props.seller.form.cpf.length < 12 ? "CPF" : "CNPJ"}%20é%20${props.seller.form.cpf}%20e%20gostaria%20de%20falar%20sobre%20meu%20acordo`)}><WhatsAppIcon/>Whatsapp</Button>
        <DialogCallMe open={dialogOpen} seller={props.seller} handleClose={() => setDialogOpen(false)}/>
    </Container>
}

export default Channel
import React, {useState} from 'react'
import {
    Container,
    Button,
    Download,
    SocialButton,
    SocialItem,
    SocialList,
    PoweredBy,
    Group,
    Divider,
    Dialog,
    QRCodeContainer,
    DialogContent
} from './styled'

import {ReactComponent as GooglePlayIcon} from 'assets/icons/googlePlay.svg'
import {ReactComponent as AppStoreIcon} from 'assets/icons/appStore.svg'
import {ReactComponent as FacebookIcon} from 'assets/icons/facebook.svg'
import {ReactComponent as TwitterIcon} from 'assets/icons/twitter.svg'
import {ReactComponent as YoutubeIcon} from 'assets/icons/youtube.svg'
import {ReactComponent as DigitalkIcon} from 'assets/icons/logo-digitalk.svg'
import {ReactComponent as InstagramIcon} from 'assets/icons/instagram.svg'
import {ReactComponent as BackIcon} from 'assets/icons/back.svg'
import {isDesktop} from 'react-device-detect';
import VanillaQR from "vanillaqr"
import useStateCallback from "../../../../utils/useStateCallback";

const Footer = () => {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [qrCode, setQrCode] = useStateCallback('');
    const socialMediaList = [
        {name: 'Facebook', link: 'facebook.com', icon: <FacebookIcon/>},
        {name: 'Youtube', link: 'youtube.com', icon: <YoutubeIcon/>},
        {name: 'Twitter', link: 'twitter.com', icon: <TwitterIcon/>},
        {name: 'Instagram', link: 'instagram.com', icon: <InstagramIcon/>},
    ]
    const handleOpenDialog = (type) => {
        const link = type === 'google' ? 'https://www.google.com.br' : 'https://www.apple.com/br'
        if (isDesktop) {
            setQrCode(new VanillaQR({
                url: link,
                noBorder: true,
            }).toImage('png').src, () => {
                setOpenDialog(type)
            })
        } else {
            let a = document.createElement('a')
            a.href = link
            a.target = '_blank'
            a.style = 'display:none;'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        }
    }
    return <Container>
        <Group>
            <SocialList>
                {socialMediaList.map(social => <SocialItem>
                    <SocialButton>
                        {social.icon}
                    </SocialButton>
                </SocialItem>)}
            </SocialList>
            <PoweredBy>
                Powered by <a href="https://digitalk.com.br/" target="_blank"><DigitalkIcon/></a>
            </PoweredBy>
        </Group>

    </Container>
}

export default Footer